import React, { ChangeEvent } from 'react';

export interface TextAreaProps {
    label: string;
    placeholder: string;
    onChange: (value: string) => void;
}

const TextArea: React.FC<TextAreaProps> = ({ label, placeholder, onChange }) => {
    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        onChange(value);
    };

    return (
        <div className='flex flex-col font-poppins'>
            <label className='font-bold text-white text-lg lg:text-xl 3xl:text-2xl mb-4 border-none'>
                {label}
            </label>
            <textarea
                className='bg-secondary-black rounded px-3 py-3 text-gray-300 text-lg lg:text-xl 3xl:text-2xl placeholder-gray-600 h-28 3xl:h-20 border-2 border-transparent hover:border-custom-yellow focus:border-custom-yellow outline-none'
                placeholder={placeholder}
                onChange={handleTextChange}
            />
        </div>
    );
};

export default TextArea;
