import React from 'react';
import SecondaryButton from '../ui/SecondaryButton';
import strings from '../../constants/strings';
import { useNavigate } from 'react-router-dom';

export interface PackageCardItemData {
    id: number;
    title: string;
    description: string;
    image: string;
    isImageTop: boolean;
    alt: string;
}

interface PackageCardItemProps {
    item: PackageCardItemData;
}

const PackageCardItem: React.FC<PackageCardItemProps> = ({ item }) => {

    const navigate = useNavigate();

    return (
        <div className='flex flex-col gap-4 items-center font-poppins pb-10 bg-secondary-black'>
            <div className='relative w-full h-[258px]'>
                <img className={`absolute w-full h-full object-cover ${item.isImageTop ? 'object-top' : 'object-center'}`} src={item.image} alt={item.alt} />
            </div>
            <h4 className='text-white font-bold text-lg lg:text-2xl text-center'>{item.title}</h4>
            <p className='text-white text-md text-center mx-4'>{item.description}</p>
            <div className='mt-auto'>
                <SecondaryButton title={strings.learnMore} onClick={() => {
                    navigate(`/package/${item.id}`);
                }} />
            </div>
        </div>
    );
};

export default PackageCardItem;