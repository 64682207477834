import React, { useState } from 'react';

import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";


export interface QuestionItemData {
    id: number;
    question: string;
    answer: string;
    isExpanded: boolean;
}

interface QuestionItemProps {
    item: QuestionItemData;
}

const QuestionItem: React.FC<QuestionItemProps> = ({ item }) => {

    const [isExpanded, setIsExpanded] = useState(item.isExpanded);


    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`flex flex-row gap-4 bg-secondary-black p-5 items-center ${isExpanded ? 'max-h-full' : 'max-h-full md:h-[75px]'}`}>
            <button className='w-10 mb-auto' onClick={handleExpand}>
                {!isExpanded ? <FaPlus className='text-custom-yellow text-2xl md:text-3xl' /> : <FaMinus className='text-custom-yellow text-2xl md:text-3xl' />}
            </button>
            <div className='flex flex-col gap-4 font-poppins max-w-[512px]'>
                <h5 className='text-white'>{item.question}</h5>
                {isExpanded && <p className='text-white'>{item.answer}</p>}
            </div>
        </div>
    );
};

export default QuestionItem;