import React from 'react';

interface ImageModalProps {
    imageUrl: string;
    alt: string;
    onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ imageUrl, alt, onClose }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 overflow-auto">
            <div className="relative max-w-full max-h-full p-4">
                <button 
                    onClick={onClose} 
                    className="absolute top-0 right-0 mt-4 mr-4 text-white text-3xl"
                >
                    &times;
                </button>
                <img 
                    src={imageUrl} 
                    alt={alt} 
                    className="max-w-full max-h-screen object-contain" 
                />
            </div>
        </div>
    );
};

export default ImageModal;
