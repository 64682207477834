import { aboutUsImg1, aboutUsImg2 } from "../../assets/images";
import AboutUsMainBanner from "../../components/sections/AboutUsMainBanner";
import DescSection from "../../components/sections/DescSection";
import InfoSection from "../../components/sections/InfoSection";
import OfferingsGrid from "../../components/sections/OfferingsGrid";
import strings from "../../constants/strings";

const AboutUsScreen = () => {
    return (
        <div className="flex flex-col">
            <AboutUsMainBanner />
            <InfoSection 
                title={strings.aboutusTitle} 
                content={strings.aboutusContent} 
                imageUrl={aboutUsImg1}
                alt={strings.aboutusAlt}
                button={true}
                reverse={true}
                imgSize={'small'}
            />
            <OfferingsGrid />
            <InfoSection 
                title={strings.corporateTitle} 
                content={strings.corporateContent} 
                imageUrl={aboutUsImg2}
                alt={strings.corporateAlt}
                button={true}
                imgSize={'medium'}
            />
            <DescSection 
                title={strings.socialCommitmentTitle} 
                subtitle={strings.socialCommitmentSubtitle} 
            />
        </div>
    );
};


export default AboutUsScreen;

