import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


interface PrimaryButtonProps {
    title: string;
    onClick: () => void;
    disabled?: boolean;
    isLoading?: boolean;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ title, onClick, disabled = false, isLoading = false }) => {

    return (
        <div>
            <button
                className={`font-poppins text-lg md:text-xl lg:text-2xl font-bold text-left focus:outline-none rounded-xl  ${disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-custom-yellow'} `}
                onClick={onClick}
                disabled={disabled}
            >
                {isLoading ? (
                    <div className="flex items-center justify-center w-20 h-12 md:w-36 md:h-14">
                        <CircularProgress size={24} color="inherit" />
                    </div>
                ) : (
                    <p className={'text-black py-3 px-2 md:px-4 lg:px-7'}>{title}</p>
                )}
            </button>
        </div>
    );
};

export default PrimaryButton;