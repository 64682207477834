import React from 'react';
import { successImg } from '../../assets/images';
import PrimaryButton from '../ui/PrimaryButton';
import SecondaryButton from '../ui/SecondaryButton';
import strings from '../../constants/strings';

interface SuccessComponentProps {
    onPrimaryButtonClick: () => void;
    onSecondaryButtonClick: () => void;
}

const SuccessComponent: React.FC<SuccessComponentProps> = ({ onPrimaryButtonClick, onSecondaryButtonClick }) => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white font-poppins">
            <div className="flex flex-col items-center">
                <div className="mt-10 md:mt-4 rounded-full">
                    <img src={successImg} alt="Paper Airplane" className="w-[200px]" />
                </div>
                <h1 className="my-4 text-3xl md:text-5xl 3xl:text-6xl font-bold">{strings.successTitle}</h1>
                <div className='mb-4 w-32 h-2 rounded bg-custom-yellow' />
                <p className="md:mb-16 mx-10 md:w-[600px] text-center">
                    {strings.successContent}
                </p>
                <div className='flex flex-col md:flex-row items-center justify-center'>
                    <div className="my-4 md:my-0 md:mr-4">
                        <PrimaryButton
                            title={strings.successPrimaryBtn}
                            onClick={onPrimaryButtonClick}
                        />
                    </div>
                    <div className='mb-10 md:mb-0'>
                        <SecondaryButton
                            title={strings.successSecundaryBtn}
                            onClick={onSecondaryButtonClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessComponent;
