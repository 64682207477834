import React, { useState } from 'react';
import CardItem, { CardItemData } from '../rows/CardItem';
import { whatsIncludedList } from "../../constants/data";
import strings from "../../constants/strings";
import PrimaryButton from '../ui/PrimaryButton';
import ImageModal from '../ui/ImageModal';

interface WhatsIncludedGridProps {
    onSelectPackage: () => void;
}

const WhatsIncludedGrid: React.FC<WhatsIncludedGridProps> = ({ onSelectPackage }) => {
    const [selectedImage, setSelectedImage] = useState<{ imageUrl: string; alt: string } | null>(null);

    const handleImageClick = (imageUrl: string, alt: string) => {
        setSelectedImage({ imageUrl, alt });
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    return (
        <div className="flex justify-center items-center text-white py-12 px-8 md:px-4 font-poppins">
            <div className="max-w-screen-2xl md:mx-28">
                <div className='flex flex-col items-center pb-12'>
                    <h2 className="text-center text-xl md:text-3xl lg:text-4xl font-bold px-6">{strings.whatsIncludedTitle}</h2>
                    <div className="flex justify-center pt-4">
                        <div className="w-32 h-1 rounded-full bg-custom-yellow"></div>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {whatsIncludedList.map((item: CardItemData) => (
                        <CardItem
                            key={item.id}
                            item={item}
                            onImageClick={handleImageClick}
                        />
                    ))}
                </div>
                <div className='flex items-center justify-center mt-8'>
                    <PrimaryButton
                        title={strings.selectThisPackage}
                        onClick={onSelectPackage}
                    />
                </div>
            </div>
            {selectedImage && (
                <ImageModal 
                    imageUrl={selectedImage.imageUrl} 
                    alt={selectedImage.alt} 
                    onClose={closeModal} 
                />
            )}
        </div>
    );
};

export default WhatsIncludedGrid;
