import { useEffect, useState } from "react";
import FormGrid from "../../components/sections/FormGrid";
import SuccessComponent from "../../components/sections/SuccessSection";
import { useNavigate, useLocation } from "react-router-dom";


const ContactUsScreen = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [showSuccess, setShowSuccess] = useState(false);
    const [selectedPackageId, setSelectedPackageId] = useState<number>(1);


    const handlePrimaryButtonClick = () => {
        setShowSuccess(false);
    };

    const handleSecondaryButtonClick = () => {
        navigate('/home');
    };

    useEffect(() => {
        if (showSuccess) {
            window.scrollTo(0, 0);
        }
    }, [showSuccess]);

    useEffect(() => {
        if (location.state) {
            setSelectedPackageId(location.state.packageId);
        }
    }, [location.state]);

    return (
        <div className="flex flex-col">
            {!showSuccess && <FormGrid packageSelectedId={selectedPackageId} onSentSuccess={() => { setShowSuccess(true) }} />}
            {showSuccess && <SuccessComponent onPrimaryButtonClick={() => handlePrimaryButtonClick()} onSecondaryButtonClick={() => handleSecondaryButtonClick()} />}
        </div>
    );
};


export default ContactUsScreen;

