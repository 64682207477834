import React from 'react';

interface PrimaryButtonProps {
    title: string;
    onClick: () => void;
}

const SecondaryButton: React.FC<PrimaryButtonProps> = ({ title, onClick }) => {

    return (
        <div>
            <button className="flex font-poppins text-lg md:text-xl lg:text-2xl font-bold focus:outline-none rounded-xl border-2 border-custom-yellow" onClick={onClick}>
                <p className={'text-custom-yellow py-3 px-2 md:px-4 lg:px-7'}>{title}</p>
            </button>
        </div>
    );
};

export default SecondaryButton;