import React from 'react';
import CardItem, { CardItemData } from '../rows/CardItem';
import { howToList } from '../../constants/data';

const HowToGrid = () => {
    return (
        <div className="bg-secondary-black text-white py-12 px-4 flex justify-center">
            <div className="flex max-w-screen-2xl md:mx-28 justify-center items-center">                
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-4 md:px-24 xl:px-0">
                    {howToList.map((item: CardItemData) => (
                        <CardItem
                            key={item.id}
                            item={item}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HowToGrid;
