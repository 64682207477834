import React from 'react';

import { packagesList } from '../../constants/data';
import PackageCardItem, { PackageCardItemData } from '../rows/PackageCardItem';
import strings from '../../constants/strings';

interface OurPackagesGridProps {
    title?: string;
}

const OurPackagesGrid: React.FC<OurPackagesGridProps> = ({ title }) => {

    return (

        <div className="flex flex-col px-10 xl:px-32 py-12 font-poppins">

            <div className="flex flex-row justify-between">

                <div className='flex flex-col'>
                    <h3 className='font-bold text-custom-yellow text-xl md:text-3xl lg:text-4xl mb-2 md:mb-4'>{strings.ourPackages}</h3>
                    <div className='max-w-32 h-1 rounded bg-custom-yellow' />
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-12 md:py-12">
                {packagesList.map((item: PackageCardItemData) => (
                    <PackageCardItem
                        key={item.id}
                        item={item} />
                ))}
            </div>

        </div>

    );
};

export default OurPackagesGrid;