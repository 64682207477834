import React, { useState } from 'react';
import strings from '../../constants/strings';

interface TermsAndConditionsProps {
    onCheck: (checked: boolean) => void;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ onCheck }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCheckboxChange = () => {
        const newChecked = !isChecked;
        setIsChecked(newChecked);
        onCheck(newChecked);
    };

    const handleModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2 text-white font-poppins text-lg lg:text-xl 3xl:text-2xl">
            <div className="flex items-center">
                <input
                    type="checkbox"
                    id="terms"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="hidden"
                />
                <label
                    htmlFor="terms"
                    className={`w-6 h-6 flex items-center justify-center border-2 rounded ${isChecked ? 'border-yellow-500 bg-yellow-500' : 'border-yellow-500'}`}
                >
                    {isChecked && (
                        <svg
                            className="w-4 h-4 text-black"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                    )}
                </label>
            </div>
            <label htmlFor="terms" className="flex flex-col sm:flex-row items-center cursor-pointer">
                <span>I agree to the</span>
                <span onClick={handleModalToggle} className="ml-1 text-yellow-500 underline">terms & conditions</span>
                <span className="ml-1">provided by the company.</span>
            </label>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-600 bg-opacity-75">
                    <div className="bg-black text-white p-8 rounded-lg w-11/12 md:w-3/4 lg:w-1/2 max-h-screen overflow-hidden">
                        <div className="flex justify-between items-center mb-4 sticky top-0 bg-black z-10">
                            <h2 className="text-md lg:text-lg 3xl:text-xl text-custom-yellow font-bold">{strings.terms}</h2>
                            <button onClick={handleModalToggle} className="text-white">
                                &times;
                            </button>
                        </div>
                        <div className='text-white !text-sm !lg:text-md !3xl:text-lg overflow-y-auto max-h-[calc(100vh-200px)] modal-content'>
                            <h3 className="font-bold">{strings.title1}</h3>
                            <p className='mb-3'>{strings.paragraph1}</p>
                            <h3 className="font-bold">{strings.title2}</h3>
                            <p className='mb-3'>{strings.paragraph2}</p>
                            <h3 className="font-bold">{strings.title3}</h3>
                            <p className='mb-3'>{strings.paragraph3}</p>
                            <h3 className="font-bold">{strings.title4}</h3>
                            <p className='mb-3'>{strings.paragraph4}</p>
                            <h3 className="font-bold">{strings.title5}</h3>
                            <p className='mb-3'>{strings.paragraph5}</p>
                            <h3 className="font-bold">{strings.title6}</h3>
                            <p className='mb-3'>{strings.paragraph6}</p>
                            <h3 className="font-bold">{strings.title7}</h3>
                            <p className='mb-3'>{strings.paragraph7}</p>
                            <h3 className="font-bold">{strings.title8}</h3>
                            <p className='mb-3'>{strings.paragraph8}</p>
                            <h3 className="font-bold">{strings.title9}</h3>
                            <p className='mb-3'>{strings.paragraph9}</p>
                            <h3 className="font-bold">{strings.title10}</h3>
                            <p className='mb-3'>{strings.paragraph10}</p>
                            <h3 className="font-bold">{strings.title11}</h3>
                            <p className='mb-3'>{strings.paragraph11}</p>
                            <h3 className="font-bold">{strings.title12}</h3>
                            <p className='mb-3'>{strings.paragraph12}</p>
                            <h3 className="font-bold">{strings.title13}</h3>
                            <p className='mb-3'>{strings.paragraph13}</p>
                            <h3 className="font-bold">{strings.title14}</h3>
                            <p className='mb-3'>{strings.paragraph14}</p>
                            <h3 className="font-bold">{strings.title15}</h3>
                            <p className='mb-3'>{strings.paragraph15}</p>
                            <h3 className="font-bold">{strings.title16}</h3>
                            <p className='mb-3'>{strings.paragraph16}</p>
                            <h3 className="font-bold">{strings.title17}</h3>
                            <p className='mb-3'>{strings.paragraph17}</p>
                            <h3 className="font-bold">{strings.title18}</h3>
                            <p className='mb-3'>{strings.paragraph18}</p>
                            <h3 className="font-bold">{strings.title19}</h3>
                            <p className='mb-3'>{strings.paragraph19}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TermsAndConditions;
