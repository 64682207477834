import React from 'react';
import PrimaryButton from '../ui/PrimaryButton';
import strings from '../../constants/strings';
import { useNavigate } from 'react-router-dom';

interface InfoSectionProps {
    title: string;
    content: string;
    imageUrl: string;
    alt: string;
    button?: boolean;
    reverse?: boolean;
    imgSize?: 'small' | 'medium' | 'large';
}

const InfoSection: React.FC<InfoSectionProps> = ({ title, content, imageUrl, alt, button = false, reverse = false, imgSize }) => {

    const navigate = useNavigate();

    const navigateToContactUsPage = () => {
        navigate('/contactus');
        window.scrollTo(0, 0);
    };
    
    const flexDirectionClass = reverse ? 'lg:flex-row-reverse' : 'lg:flex-row';
    const justifyImg = reverse ? '' : 'justify-end ';
    const backgroundColor = button ? 'bg-black' : 'bg-secondary-black';
    const imgPosition = imgSize === 'large' ? 'top center' : 'right center';

    return (
        <div className={`flex flex-col ${flexDirectionClass} ${backgroundColor} gap-10 justify-center items-center md:items-start p-10 lg:px-20 xl:px-[130px] font-poppins`}>
            {/* Text Section */}
            <div className="flex-1 flex flex-col justify-center items-center text-center md:items-start md:text-left">
                <h1 className="font-bold text-center text-custom-yellow text-2xl md:text-4xl md:text-left">{title}</h1>
                <div className='flex flex-row justify-center items-center pt-4 pb-10 3xl:pt-6 3xl:pb-12'>
                    <div className="w-32 h-1 rounded-full bg-custom-yellow"></div>
                </div>
                <p className="mb-8 text-white text-md md:text-2xl">
                    {content}
                </p>
                {button && (
                    <div className='mb-8 md:mb-0'>
                        <PrimaryButton
                            title={strings.contactUs}
                            onClick={navigateToContactUsPage}
                        />
                    </div>
                )}
            </div>

            {/* Image Section */}
            <div className={`mb-8 2xl:mb-0 2xl:flex-1 flex items-center ${justifyImg}`}>
                <div className="xl:h-90 overflow-hidden">
                    <img src={imageUrl} alt={alt} className={`object-cover h-60 md:h-[400px] w-[600px] object-center`} style={{ objectPosition: imgPosition }} />
                </div>
            </div>
        </div>
    );
};

export default InfoSection;
