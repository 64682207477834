import React from 'react';
import PrimaryButton from '../ui/PrimaryButton';
import strings from '../../constants/strings';
import { useNavigate } from 'react-router-dom';

interface ContactUsBannerProps {
    title?: string;
    onClick?: () => void;
}

const ContactUsBanner: React.FC<ContactUsBannerProps> = ({ title, onClick }) => {

    const navigate = useNavigate();

    const navigateToContactUsPage = () => {
        navigate('/contactus');
        window.scrollTo(0, 0);
    };

    return (
        <div className="w-full h-[350px] flex-col bg-banner-home2 bg-cover bg-center">
            <div className='flex w-full h-full bg-black bg-opacity-50'>
                <div className='flex flex-col w-full h-full items-center justify-center text-white font-poppins lg:px-72 3xl:px-64 md:px-0'>
                    <h3 className='font-bold text-center text-2xl md:text-4xl lg:text-5xl 3xl:text-6xl mb-6 lg:mb-10 !leading-[40px] md:!leading-[75px]'>{title}</h3>
                    <PrimaryButton
                        title={strings.contactUs}
                        onClick={navigateToContactUsPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default ContactUsBanner;