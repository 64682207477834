import React from 'react';
import strings from '../../constants/strings';
import PrimaryButton from '../ui/PrimaryButton';

interface MainBannerProps {
    packageData: any;
    onSelectPackage: () => void;
}

const PackageMainBanner: React.FC<MainBannerProps> = ({ packageData, onSelectPackage }) => {

    return (
        <div className={`w-full h-[590px] flex-col bg-cover bg-center`} style={{backgroundImage: `url(${packageData.banner})`}}>
            <div className='flex text-white font-poppins w-full h-full bg-black bg-opacity-50'>
                <div className='flex flex-col justify-center w-full xl:w-8/12 px-10 md:px-32 lg:px-32 py-10'>
                    <h2 className='font-bold text-2xl md:text-4xl lg:text-6xl whitespace-pre mb-4'>{packageData.title}</h2>
                    <div className='max-w-32 h-1 rounded bg-custom-yellow mb-6' />
                    <h4 className='text-md md:text-xl mb-6 mr-0 lg:mr-24'>{packageData.subtitle}</h4>
                    <PrimaryButton
                        title={strings.selectThisPackage}
                        onClick={onSelectPackage}
                    />
                </div>
            </div>
        </div>
    );
};

export default PackageMainBanner;