
import React from 'react';

interface DescSectionProps {
    title: string;
    subtitle: string;
}

const DescSection: React.FC<DescSectionProps> = ({ title, subtitle }) => {
    return (
        <div className='flex w-full bg-custom-black'>
            <div className='flex flex-col w-full justify-center items-center font-poppins px-10 md:px-0 py-8'>
                <h3 className='max-w-[960px] font-bold text-center text-custom-yellow text-2xl md:text-4xl mb-3 xl:!leading-[50px]'>{title}</h3>
                <div className='w-32 max-w-32 h-1 rounded bg-custom-yellow mb-6' />
                <h4 className='max-w-[960px] text-center text-white text-md md:text-2xl mb-3 xl:!leading-[35px]'>{subtitle}</h4>
            </div>
        </div>
    );
};

export default DescSection;