import React, { useEffect, useState } from 'react';
import { package1, package2, package3 } from './../../assets/images';

interface Package {
    id: number;
    name: string;
    image: string;
}

interface PackageSelectionProps {
    packageSelectedId: number;
    onPackageSelect: (name: string) => void;
}

const packages: Package[] = [
    {
        id: 1,
        name: 'Special Events',
        image: package1,
    },
    {
        id: 2,
        name: 'Corporate Events',
        image: package2,
    },
    {
        id: 3,
        name: 'Nonprofit Events',
        image: package3,
    },
];

const PackageSelection: React.FC<PackageSelectionProps> = ({ packageSelectedId, onPackageSelect }) => {
    const [selectedPackage, setSelectedPackage] = useState<number | null>(packageSelectedId);

    const handlePackageSelect = (id: number, name: string) => {
        setSelectedPackage(id);
        onPackageSelect(name);
    };

    useEffect(() => {     
        setSelectedPackage(+packageSelectedId);
        onPackageSelect(packages.find(pkg => pkg.id === +packageSelectedId)?.name || '');
    }, [packageSelectedId]);

    return (
        <div className="flex flex-col md:flex-row md:space-x-8 3xl:space-x-10 font-poppins items-center justify-center mt-2 mb-4 mx-4 lg:mx-2">
            {packages.map((pkg) => (
                <div
                    key={pkg.id}
                    className={`my-4 relative rounded-lg transition-transform transform ${selectedPackage === pkg.id ? 'border-yellow-500 border-2' : 'border-transparent border-2'
                        }`}
                    onClick={() => handlePackageSelect(pkg.id, pkg.name)}
                >
                    <img
                        src={pkg.image}
                        alt={pkg.name}
                        className={`md:h-60 md:w-[395px] 3xl:h-72 3xl:w-[465px] rounded-lg object-cover object-center 
                            ${selectedPackage === pkg.id ? 'opacity-100' : 'opacity-60'
                            }`}
                    />
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/70 rounded-lg"></div>
                    <div className="absolute inset-0 flex items-end mb-4 justify-center">
                        <span className="text-white text-lg">{pkg.name}</span>
                    </div>
                    {selectedPackage === pkg.id && (
                        <div className="absolute top-2 left-2 w-4 h-4 3xl:w-6 3xl:h-6 bg-yellow-500 rounded-full border-2 border-white"></div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default PackageSelection;
