import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';

export interface CardItemData {
    id: number;
    title: string;
    content: string;
    imageUrl: string;
    alt: string;
    size?: string;
    isImageTop?: boolean;
}

interface CardItemProps {
    item: CardItemData;
    onImageClick?: (imageUrl: string, alt: string) => void;
}

const CardItem: React.FC<CardItemProps> = ({ item, onImageClick }) => {
    const imageClassName = item.size === 'square' ? 'h-60 w-full' : '';
    const divImgClassName = item.size === 'square' ? 'relative w-full h-[258px]' : '';
    const topClassName = item.isImageTop ? 'object-top' : 'object-center';

    const handleClick = () => {
        if (onImageClick) {
            onImageClick(item.imageUrl, item.alt);
        }
    };

    return (
        <div className="flex flex-col items-start justify-start font-poppins w-full text-white gap-4">
            <div className={`${divImgClassName} cursor-pointer relative`} onClick={handleClick}>
                <img 
                    src={item.imageUrl} 
                    alt={item.alt} 
                    className={`${imageClassName} ${topClassName} object-cover cursor-pointer`} 
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300">
                    <FontAwesomeIcon icon={faSearchPlus} className="text-white text-2xl" />
                </div>
            </div>
            <div className="text-left">
                <h3 className="font-bold text-xl lg:text-xl 3xl:text-2xl text-left mb-2">{item.title}</h3>
                <div className="flex justify-start mb-2">
                    <div className="w-32 h-1 rounded-full bg-secondary-purple"></div>
                </div>
                <p className="text-white-600 text-md md:text-xl text-left">
                    {item.content}
                </p>
            </div>
        </div>
    );
};

export default CardItem;
