import { useNavigate } from 'react-router-dom';

import React from 'react';
import SecondaryButton from '../ui/SecondaryButton';
import strings from '../../constants/strings';


interface MainBannerProps {
    title?: string;
    onClick?: () => void;
}

const MainBanner: React.FC<MainBannerProps> = ({ title, onClick }) => {
    const navigate = useNavigate();

    const navigateToAboutUsPage = () => {
        navigate('/aboutus');
        window.scrollTo(0, 0);
    };

    return (

        <div className="w-full h-[590px] flex-col bg-banner-home1 bg-cover bg-center">
            <div className='flex text-white font-poppins w-full h-full bg-black bg-opacity-50 '>
                <div className='flex flex-col justify-center w-full xl:w-8/12 px-10 md:px-32 lg:px-32 py-10'>
                    <h2 className='font-bold text-2xl md:text-4xl lg:text-6xl whitespace-pre mb-4'>{strings.mainBannerTitle}</h2>
                    <h4 className='text-md md:text-xl mb-6 mr-0 lg:mr-24'>{strings.mainBannerContent}</h4>
                    <SecondaryButton
                        title={strings.learnMore}
                        onClick={navigateToAboutUsPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default MainBanner;