import React, { ChangeEvent } from 'react';

export interface TextInputProps {
    label: string;
    placeholder: string;
    onChange: (value: string) => void;
}

const TextInput: React.FC<TextInputProps> = ({ label, placeholder, onChange }) => {
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        onChange(value);
    };

    return (
        <div className='flex flex-col font-poppins'>
            <label className='font-bold text-white text-lg lg:text-xl 3xl:text-2xl mb-4 border-none'>
                {label}
            </label>
            <input
                type='text'
                className='bg-secondary-black rounded px-3 text-gray-300 text-lg lg:text-xl 3xl:text-2xl placeholder-gray-600 h-16 3xl:h-20 border-2 border-transparent hover:border-custom-yellow focus:border-custom-yellow outline-none'
                placeholder={placeholder}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default TextInput;
