import React from 'react';
import PrimaryButton from '../ui/PrimaryButton';
import SecondaryButton from '../ui/SecondaryButton';

import TypeItem, { TypeItemData } from '../rows/TypeItem';
import { useNavigate } from 'react-router-dom';

interface EventTypesGridProps {
    title: string;
    content: string;
    buttonTitle: string;
    types: TypeItemData[];
    buttonType?: 'primary' | 'secondary';
}

const EventTypesGrid: React.FC<EventTypesGridProps> = ({ title, content, types, buttonTitle, buttonType }) => {
    const ButtonComponent = buttonType === 'primary' ? PrimaryButton : SecondaryButton;

    const navigate = useNavigate();

    const navigateToContactUsPage = () => {
        navigate('/contactus');
        window.scrollTo(0, 0);
    };
    
    return (
        <div className="flex flex-col gap-5 md:gap-8 lg:gap-12 md:flex-row px-10 xl:px-32 bg-secondary-black text-center items-center">
            <div className="flex flex-col md:w-6/12 gap-11 pt-12 md:py-12 font-poppins md:items-start md:text-left">
                <div>
                    <h3 className='font-bold text-custom-yellow text-2xl md:text-4xl lg:text-4xl 3xl:text-5xl mb-4'>
                        {title}
                    </h3>
                    <div className='flex justify-center md:justify-start'>
                        <div className='w-32 h-1 rounded bg-custom-yellow' />
                    </div>
                </div>

                <p className='text-white lg:text-lg'>{content}</p>
                <ButtonComponent title={buttonTitle} onClick={navigateToContactUsPage} />
            </div>

            <div className="grid grid-cols-2 gap-6 md:w-6/12 py-12 font-poppins">
                {types.map((item: TypeItemData) => (
                    <TypeItem key={item.id} item={item} />
                ))}
            </div>
        </div>
    );
};

export default EventTypesGrid;
