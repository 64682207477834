import React from 'react';
import { GoTriangleDown } from "react-icons/go";

export interface MenuItemData {
    id: number;
    name: string;
    route: string;
    isSelected: boolean;
}

interface MenuItemProps {
    item: MenuItemData;
    isDropdownVisible: boolean;
    setDropdownVisible: (value: boolean) => void;
    onClickItem: (route: string) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ item, isDropdownVisible, setDropdownVisible, onClickItem }) => {

    const handleOnClickItem = (route: string) => {
        onClickItem(route);
    };

    return (
        item.id === 5 ? (
            <button className="font-poppins text-lg lg:text-xl xl:text-2xl font-bold text-left focus:outline-none lg:rounded-xl lg:bg-custom-yellow" onClick={() => handleOnClickItem(item.route)}>
                <p className={`${item.isSelected ? 'text-custom-yellow' : 'text-white'} lg:text-black lg:py-2 lg:px-5`}>{item.name}</p>
            </button>
        ) : item.id === 2 ? (
            <div
                className="relative flex items-center"
                onClick={() => setDropdownVisible(!isDropdownVisible)}
                onMouseEnter={() => setDropdownVisible(true)}
                onMouseLeave={() => setDropdownVisible(false)}
            >
                <button className="relative flex items-center gap-2 font-poppins text-lg lg:text-xl xl:text-2xl font-bold text-left focus:outline-none">
                    <p className={` ${item.isSelected ? 'text-custom-yellow' : 'text-white'}`}>{item.name}</p>
                    <GoTriangleDown className={` ${item.isSelected ? 'text-custom-yellow' : 'text-white'}`} />
                </button>
                {isDropdownVisible && (
                    <div className='absolute top-full left-0 z-20'>
                        <div className=" bg-custom-yellow shadow-lg rounded-md mt-2">
                            <ul className='font-poppins text-black'>
                                <li className="px-4 py-2 hover:font-bold cursor-pointer" onClick={() => { handleOnClickItem('/package/1'); }}>Special Events</li>
                                <li className="px-4 py-2 hover:font-bold cursor-pointer" onClick={() => { handleOnClickItem('/package/2'); }}>Corporate Events</li>
                                <li className="px-4 py-2 hover:font-bold cursor-pointer" onClick={() => { handleOnClickItem('/package/3'); }}>Nonprofit Events</li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <button className="font-poppins text-lg lg:text-xl xl:text-2xl font-bold text-left focus:outline-none" onClick={() => handleOnClickItem(item.route)}>
                <p className={` ${item.isSelected ? 'text-custom-yellow' : 'text-white'}`}>{item.name}</p>
            </button>
        )
    );
};

export default MenuItem;
