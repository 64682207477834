import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import emailjs from '@emailjs/browser';

import strings from '../../constants/strings';
import constants from '../../constants/constants';

import PackageSelection from '../rows/PackageSelection';
import TextInput from '../rows/TextInput';
import PrimaryButton from '../ui/PrimaryButton';
import TextArea from '../rows/TextArea';
import TermsAndConditions from '../rows/TermsAndConditions';
import ValidationMessage from '../ui/ValidationMessage';

interface FormGridProps {
    packageSelectedId: number;
    onSentSuccess: () => void;
}

const FormGrid: React.FC<FormGridProps> = ({ packageSelectedId, onSentSuccess }) => {
    const [packageName, setPackageName] = useState('Special Events');
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [venue, setVenue] = useState('');
    const [setup, setSetup] = useState('');
    const [specialRequests, setSpecialRequests] = useState('');
    const [validationMessages, setValidationMessages] = useState({
        name: '',
        address: '',
        email: '',
        phone: '',
        venue: '',
        setup: '',
    });
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Add loading state

    const validateForm = () => {
        const messages: any = {};

        if (name === '') {
            messages.name = 'Please enter your name';
        }
        if (address === '') {
            messages.address = 'Please enter your address';
        }
        if (email === '') {
            messages.email = 'Please enter your email';
        }
        if (phone === '') {
            messages.phone = 'Please enter your phone number';
        }
        if (venue === '') {
            messages.venue = 'Please enter the event venue';
        }
        if (setup === '') {
            messages.setup = 'Please enter the event setup';
        }
        
        setValidationMessages(messages);

        return Object.keys(messages).length === 0;
    };

    const sendEmail = () => {
        if (!validateForm()) {
            return;
        }

        setIsLoading(true); // Set loading state to true

        const templateParams = {
            package_selected: packageName,
            user_name: name,
            user_company: company,
            user_address: address,
            user_email: email,
            user_phone: phone,
            event_start_date: startDate?.format('YYYY-MM-DD, hh:mm A'),
            event_end_date: endDate?.format('YYYY-MM-DD, hh:mm A'),
            event_venue: venue,
            event_setup: setup,
            special_requests: specialRequests,
        };

        emailjs.send(constants.emailServiceId, constants.emailTemplateId, templateParams, constants.emailApiKey)
            .then(
                (response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    onSentSuccess();
                    setIsLoading(false); // Set loading state to false
                },
                (error) => {
                    console.log('FAILED...', error);
                    alert('Error sending email. Please try again later.');
                    setIsLoading(false); // Set loading state to false
                }
            );
    };

    return (
        <div className='flex items-center justify-center bg-black'>
            <div className='max-w-screen-2xl w-full px-4 3xl:px-0'>
                <div className='flex flex-col justify-center items-center'>
                    <h2 className='font-bold text-2xl md:text-4xl lg:text-6xl mt-8 mb-4 text-white'>{strings.contactUs}</h2>
                    <div className='w-32 h-2 rounded bg-custom-yellow' />
                </div>                
                <div className='px-4 md:px-[112px] 3xl:px-6 mt-8'>
                    <label className='font-bold text-white text-lg lg:text-xl 3xl:text-2xl mb-4 border-none'>
                        {strings.packageLabel}
                    </label>
                </div>
                <div className=''>
                    <PackageSelection packageSelectedId={packageSelectedId} onPackageSelect={(value) => setPackageName(value)} />
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 px-4 md:px-[112px] 3xl:px-6'>
                    <div className='mb-4'>
                        <TextInput label={strings.nameLabel} placeholder={strings.namePlaceholder} onChange={(value) => { setName(value) }} />
                        {validationMessages.name && <ValidationMessage message={validationMessages.name} />}
                    </div>
                    <TextInput label={strings.companyLabel} placeholder={strings.companyPlaceholder} onChange={(value) => { setCompany(value) }} />
                    <div className='mb-4'>
                        <TextInput label={strings.addressLabel} placeholder={strings.addressPlaceholder} onChange={(value) => { setAddress(value) }} />
                        {validationMessages.address && <ValidationMessage message={validationMessages.address} />}
                    </div>
                    <div className='mb-4'>
                        <TextInput label={strings.emailLabel} placeholder={strings.emailPlaceholder} onChange={(value) => { setEmail(value) }} />
                        {validationMessages.email && <ValidationMessage message={validationMessages.email} />}
                    </div>
                    <div className='mb-4'>
                        <TextInput label={strings.phoneLabel} placeholder={strings.pholePlaceholder} onChange={(value) => { setPhone(value) }} />
                        {validationMessages.phone && <ValidationMessage message={validationMessages.phone} />}
                    </div>
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className='grid grid-cols-2 items-center gap-8'>
                                <div className='grid grid-cols-1 mb-4'>
                                    <label className='font-bold text-white text-lg lg:text-xl 3xl:text-2xl mb-4 border-none'>
                                        {strings.dateStart}
                                    </label>
                                    <CustomDateTimePicker
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue)}
                                    />
                                </div>

                                <div className='grid grid-cols-1 mb-4'>
                                    <label className='font-bold text-white text-lg lg:text-xl 3xl:text-2xl mb-4 border-none'>
                                        {strings.dateEnd}
                                    </label>
                                    <CustomDateTimePicker
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue)}
                                    />
                                </div>
                            </div>
                        </LocalizationProvider>
                    </ThemeProvider>
                    <div className='mb-4'>
                        <TextInput label={strings.venueLabel} placeholder={strings.venuePlaceholder} onChange={(value) => { setVenue(value) }} />
                        {validationMessages.venue && <ValidationMessage message={validationMessages.venue} />}
                    </div>
                    <div className='mb-4'>
                        <TextInput label={strings.setupLabel} placeholder={strings.setupPlaceholder} onChange={(value) => { setSetup(value) }} />
                        {validationMessages.setup && <ValidationMessage message={validationMessages.setup} />}
                    </div>
                    <TextArea label={strings.specialRequests} placeholder={strings.specialRequests} onChange={(value) => { setSpecialRequests(value) }} />
                </div>

                <div className='md:px-[112px] 3xl:px-6 mt-8'>
                    <TermsAndConditions onCheck={(checked) => setTermsAccepted(checked)} />
                </div>

                <div className=''>
                    <div className='flex flex-col items-center md:items-end my-8 md:my-8 md:mr-[115px]'>
                        <PrimaryButton
                            title={isLoading ? "Submitting..." : strings.submit} // Change title based on loading state
                            onClick={() => { sendEmail(); }}
                            disabled={!termsAccepted || isLoading} // Disable if terms not accepted or loading
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormGrid;


const CustomDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#12191C',
        color: 'white',
        height: '80px',
        [theme.breakpoints.down('lg')]: {
            height: '64px',
        },
        fontSize: '1.125rem', // Equivalent to text-lg
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.25rem', // Equivalent to lg:text-xl
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '1.5rem', // Equivalent to 3xl:text-2xl
        },
    },

    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#FFCE32',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FFCE32',
        },
    },
    '& .MuiFormLabel-root': {
        display: 'none',
    },
    '& .MuiSvgIcon-root': {
        color: 'white',
    },
}));

let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            main: '#FFCE32',
        },
    },
    typography: {
        fontFamily: 'Poppins',
        fontSize: 14,
    }
});
