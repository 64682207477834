import { useNavigate } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaRegEnvelope } from 'react-icons/fa';
import { FaLinkedin } from "react-icons/fa";
import { FaThreads } from "react-icons/fa6";
import { GrLocation } from "react-icons/gr";
import { FaXTwitter } from "react-icons/fa6";
import { TbBrandTiktok } from "react-icons/tb";
import { FiPhone } from "react-icons/fi";
import { logo1 } from '../../assets/images';
import strings from '../../constants/strings';
import { menuList } from '../../constants/data';
import { MenuItemData } from '../rows/MenuItem';

const Footer = () => {
  const navigate = useNavigate();

  const navigateToScreen = (route: string) => {
    navigate(route);
    window.scrollTo(0, 0);
  };

  return (
    <div className="bg-custom-black text-white px-7 md:px-24 py-12">
      {/* Three columns layout */}
      <div className="flex flex-col gap-12 md:flex-row w-full">
        {/* First column */}
        <div className="flex flex-col gap-6 md:w-4/12">
          <div className="flex flex-row items-center w-full gap-4">
            <img src={logo1} alt="Logo" className="w-14 lg:w-24 h-auto" />
            <div className='flex flex-col'>
              <h1 className='font-poppins font-bold text-2xl lg:text-3xl xl:text-4xl'>{strings.brandName}</h1>
              <h2 className='font-poppins text-lg lg:text-xl xl:text-2xl'>{strings.brandSector}</h2>
            </div>
          </div>
          <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-6 text-4xl text-custom-yellow">
            <a href={strings.Facebook} target='_blank'><FaFacebookF /></a>
            <a href={strings.Instagram} target='_blank'><FaInstagram /></a>
            <a href={strings.Twitter} target='_blank'><FaXTwitter /></a>
            <a href={strings.LinkedIn} target='_blank'><FaLinkedin /></a>
            <a href={strings.Tiktok} target='_blank'><TbBrandTiktok /></a>
            <a href={strings.Threads} target='_blank'><FaThreads /></a>
          </div>
        </div>

        {/* Second column */}
        <div className="flex flex-col md:w-4/12 md:ml-10">
          <h3 className='font-poppins font-bold text-2xl text-custom-yellow mb-4'>{strings.quickLinks}</h3>
          <div className='flex flex-col gap-2'>
            {menuList.map((item: MenuItemData) => (
              (item.id !== 2 && <button key={item.id} className='text-left text-white text-xl font-poppins underline' onClick={() => {
                navigateToScreen(item.route);
              }}>
                {item.name}
              </button>)

            ))}
          </div>
        </div>

        {/* Third column */}
        <div className="flex flex-col md:w-4/12">
          <h3 className='font-poppins font-bold text-2xl text-custom-yellow mb-4'>{strings.contactInfo}</h3>
          <div className='flex flex-col gap-4'>
            <div className="flex items-center space-x-4">
              <div>
                <FiPhone className='text-custom-yellow text-xl md:text-3xl lg:text-4xl' />
              </div>
              <a href={`tel:${strings.companyPhone}`} className='font-poppins text-sm md:text-md lg:text-lg'>{strings.companyPhone}</a>
            </div>
            <div className="flex items-center space-x-4">
              <div>
                <FaRegEnvelope className='text-custom-yellow text-xl md:text-3xl lg:text-4xl' />
              </div>
              <a href={`mailto:${strings.companyEmail}`} className='font-poppins text-sm md:text-md lg:text-lg'>{strings.companyEmail}</a>
            </div>
            <div className="flex items-center space-x-4">
              <div>
                <GrLocation className='text-custom-yellow text-xl md:text-3xl lg:text-4xl' />
              </div>
              <span className='font-poppins text-sm md:text-md lg:text-lg'>{strings.companyAddress}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
