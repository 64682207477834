import { Outlet } from 'react-router-dom';
import NavMenu from '../../components/sections/NavMenu';
import Footer from '../../components/sections/Footer';

const MainScreen = () => {
    return (
        <div className='flex flex-col'>
            <NavMenu />
            <Outlet />
            <Footer />
        </div>

    );
};

export default MainScreen;
