import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PackageMainBanner from "../../components/sections/PackageMainBanner";
import DescSection from "../../components/sections/DescSection";
import WhatsIncludedGrid from "../../components/sections/WhatsIncludedGrid";
import HowToGrid from "../../components/sections/HowToGrid";
import InfoSection from "../../components/sections/InfoSection";
import BookingStepsBanner from "../../components/sections/BookingStepsBanner";

import strings from "../../constants/strings";
import { packagesList } from '../../constants/data';
import { customizeImg } from '../../assets/images';

const PackagesScreen = () => {

    const navigate = useNavigate();

    const { packageId } = useParams<{ packageId?: string }>();

    const [packageData, setPackageData] = useState<any>({});

    useEffect(() => {
        if (!packageId) return;
        setPackageData(packagesList.find(pkg => pkg.id === +packageId));
    }, [packageId]);

    const handlePackageSelect = () => {
        navigate('/contactus', {
            state: { packageId: packageId },
        });
        window.scrollTo(0, 0);
    }

    return (
        <div className="flex flex-col">
            <PackageMainBanner
                packageData={packageData}
                onSelectPackage={handlePackageSelect} />
            <DescSection
                title={strings.chooseThisPackage}
                subtitle={packageData.chooseTitle}
            />
            <HowToGrid />
            <WhatsIncludedGrid onSelectPackage={handlePackageSelect} />
            <InfoSection
                title={strings.customizeTitle}
                content={strings.customizeContent}
                imageUrl={customizeImg}
                alt={strings.customizeAlt}
                imgSize={'medium'}
            />
            <BookingStepsBanner />
        </div>
    );
};


export default PackagesScreen;

