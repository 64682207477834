import React from 'react';

export interface BookingStepItemData {
    id: number;
    description: string;
}

interface BookingStepItemProps {
    item: BookingStepItemData;
}

const BookingStepItem: React.FC<BookingStepItemProps> = ({ item }) => {

    return (
        <div className='flex flex-col gap-4 items-center font-poppins'>
            <div className='flex flex-col justify-center w-20 h-20 md:w-24 md:h-24 bg-custom-yellow rounded-full text-center'>
                <h4 className=' text-black font-bold text-3xl lg:text-5xl 3xl:text-6xl '>{item.id}</h4>
            </div>
            <p className='text-white text-md text-center md:mx-12 3xl:mx-20'>{item.description}</p>
        </div>
    );
};

export default BookingStepItem;