import React from 'react';
import PrimaryButton from '../ui/PrimaryButton';
import strings from '../../constants/strings';
import { bookingStepsList } from '../../constants/data';
import BookingStepItem, { BookingStepItemData } from '../rows/BookingStepItem';
import { useNavigate } from 'react-router-dom';

interface BookingStepsBannerProps {
    title?: string;
    onClick?: () => void;
}

const BookingStepsBanner: React.FC<BookingStepsBannerProps> = ({ title, onClick }) => {

    const navigate = useNavigate();

    const navigateToContactUsPage = () => {
        navigate('/contactus');
        window.scrollTo(0, 0);
    };

    return (

        <div className="w-full min-h-[513px] flex-col bg-banner-home3 bg-cover bg-center">
            <div className='flex w-full min-h-[513px] bg-black bg-opacity-50'>
                <div className='flex flex-col w-full justify-center items-center text-white font-poppins px-10 md:px-0 py-8'>
                    <h3 className='font-bold text-center text-3xl md:text-4xl mb-3'>{strings.bookingWithUs}</h3>
                    <div className='w-32 h-2 rounded bg-custom-yellow mb-10' />

                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 py-4 mb-6 lg:px-12">
                        {bookingStepsList.map((item: BookingStepItemData) => (
                            <BookingStepItem
                                key={item.id}
                                item={item} />
                        ))}
                    </div>

                    <PrimaryButton
                        title={strings.contactUs}
                        onClick={navigateToContactUsPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default BookingStepsBanner;