const strings = {

    brandName: "SPARTACUS",
    brandSector: "ENTERTAINMENT",

    //Common

    home: "Home",
    packages: "Packages",
    aboutUs: "About us",
    contactUs: "Contact us!",
    ourPackages: "Our Packages",

    learnMore: "Learn more",
    learnMoreAbout: "Learn more about us",
    selectThisPackage: "Select this package",

    submit: "Submit",

    //Rent with us

    bookingWithUs: "Book with us in 4 simple steps!",

    //Footer

    footerText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ",
    quickLinks: "QUICK LINKS",
    contactInfo: "CONTACT INFO",
    followUs: "Follow us",
    companyAddress: "Welland, Ontario",
    companyEmail: "info@spartacusphotobooth.ca",
    companyPhone: "647-200-4149",

    //EventTypesGrid

    eventTypesGridTitle: "Choose Your Fun – A Photo Booth for Every Occasion",
    eventTypesGridContent: "Our goal is to help you create lasting memories. Whether it's a corporate event, wedding or any other celebration, we take pride in being part of those special moments.",

    //ContactUsBanner

    contactUsBannerContent: "We are always ready to take the perfect shot",

    //MainBanner

    mainBannerTitle: "Epic Moments,\nLegendary Memories",
    mainBannerContent: "Our mission at Spartacus Entertainment is to ignite joy and capture memories through our photo booth experiences. We believe that every celebration, from weddings to corporate events, deserves that extra dose of fun.",

    //Frequestly Asked Questions
    frequentlyAskedQuestions: "Frequently Asked Questions",
    
    //Links

    Instagram: 'https://www.instagram.com/spartacus_entertainment?igsh=MTd0MXc3MDlybTJkeA==',
    Facebook: 'https://www.facebook.com/profile.php?id=61557798852168&sk=about',
    Tiktok: 'https://www.tiktok.com/@spartacus.entertainment',
    Threads: 'https://www.threads.net/@spartacus_entertainment',
    Twitter: 'https://twitter.com/Spartacus_Ent',
    LinkedIn: 'https://www.linkedin.com/company/spartacusentertainment',

    //Packages
    chooseThisPackage: "Choose this package to capture the best moments instantly and in a fun way!",

    //Customize Info
    customizeTitle: "Need customization?  Let us know!",
    customizeContent: "We are able to provide customization to your Personal event.  Please let us know if you require a longer duration, different backdrop, travel outside of Niagara Region, Special Props, Event photo template customization, physical prints, or any other special requirements.",
    customizeAlt: "Girl in orange dress with party ballons",

    //What's Included
    whatsIncludedTitle: "What’s included?",
  
    //About Us
    aboutBannerText1: "We're Spartacus Entertainment, and we're all about capturing those unforgettable moments of laughter and joy at your event!",
    aboutBannerText2: "We provide stylish and interactive photo booth rentals that add a fun and unique element to any celebration. Whether it's a wedding, birthday party, corporate event, or graduation, our goal is to create lasting memories for you and your guests.",

    //About Us Info
    aboutusTitle: "We make your events memorable!",
    aboutusContent: "We're a team of passionate event enthusiasts who love creating special moments. We believe that photos have the power to transport you back in time and provide you with a special keepsake to treasure for years to come. \nLet's work together to make your next event unforgettable!",
    aboutusAlt: "Friends at an event with props",

    //Offerings Grid
    offeringsTitle: "We are committed to providing you with the best service",

    //Corporate Allies Info

    corporateTitle: "We are your ally for corporate events",
    corporateContent: "For corporate events, we work with you to assure we embrace your company's values.  We know that the goals of trade shows, conferences and exhibitions is to create awareness of your products and services or internally to provide employee engagement. Our goal is to help make that happen while providing a fun and engaging environment.",
    corporateAlt: "People in corporate outfits having fun",

    //Social Commitment 
    socialCommitmentTitle: 'Our social commitment',
    socialCommitmentSubtitle: 'As we work closely with Nonprofit organizations, we will be introducing a quarterly contest for a free photo booth experience to give back to the community.',

    //Contact Us Form

    packageLabel: "Select your package",
    
    nameLabel: "Contact Name*",
    namePlaceholder: "Enter your Contact Name",

    addressLabel: "Address*",
    addressPlaceholder: "Enter your Address",

    phoneLabel: "Telephone Number*",
    pholePlaceholder: "Enter your Telephone Number",   

    companyLabel: "Company Name",
    companyPlaceholder: "Enter your Company Name",

    emailLabel: "Email Address*",
    emailPlaceholder: "Enter your Email Address",

    venueLabel: "Venue Location*",
    venuePlaceholder: "Enter the event address",

    setupLabel: "Setup Location*",
    setupPlaceholder: "Select Setup Location",
    specialRequests: "Cutomization or special requests",

    datesLabel: "Date(s) with Start and End Time*",
    datesPlaceholder: "Select the Date(s) with Start and End Time",
    dateStart: "Start Date*",
    dateEnd: "End Date*",

    //Success Component
    successTitle: "Success!",
    successContent: "Thank you for your inquiry. We will reach out to you shortly and look forward to working with you.",
    successPrimaryBtn: "Submit another request",
    successSecundaryBtn: "Go back to home",

    //Terms and Conditions
    terms: "Terms and Conditions",

    title1: "SERVICE AGREEMENT:",
    paragraph1: "The following agreement and its terms will set forth an agreement between Spartacus Entertainment Inc (the Provider) and the requester of the service (the Client), for photo booth services. This agreement sets forth the full, written intention of both parties and supersedes all other written and/or oral agreements between the parties.",
    title2: "SERVICE PERIOD:",
    paragraph2: "The Service Period will be agreed upon based on the Client’s request.",
    title3:"EXTENDED SERVICE:",
    paragraph3:"The Client may extend the service period for an additional fee based on an hourly rate if the Provider agreed. The Client may request for an idle time for additional fee based on an hourly rate.",
    title4:"DEPOSIT PAYMENT:",
    paragraph4:"A non-refundable deposit in the amount of 20% of the total cost is due upon signing of this agreement. Payment can be made via cash, e-transfer, or cheque. The Provider will not accept cheque if the Client’s event is in less than 30 days. If payment is made via cheque, the cheque must be payable to Spartacus Entertainment Inc and must be received within 5 days after signing the agreement. For NSF cheques, Clients will be charged a $100 processing fee per incident.",
    title5: "BALANCE PAYMENT:",
    paragraph5: "Balance is due 15 days before the event date. Any other remaining balance (e.g. extension fee) is due right after the photo booth session. Payment can be made via cash, e-transfer, or cheque. If paid via cheque, the cheque must be payable to Spartacus Entertainment Inc. For NSF cheques, Clients will be charged a $100 processing fee per incident.",
    title6: "EVENT TEMPLATE FOR PHOTOS:",
    paragraph6:"Any customization for the photos (e.g. borders, logos, messaging) must be provided by the Client at least 2 weeks via email to info@spartacusentertainment.ca or the Provider will use Spartacus rtainment Inc's default photo template.",
    title7: "PROVIDER’S STANDARD PRICE LIST:",
    paragraph7:"The charges in this Agreement are based on the Provider’s Standard Price List. This price list is adjusted periodically and future orders shall be charged at the prices in effect at the time when the order is placed.",
    title8: "LOCATION REQUIREMENTS:",
    paragraph8:"The Client shall provide Spartacus Entertainment Inc with safe and appropriate working conditions and a solid floor. The Client will arrange an appropriate 8′ x 8′ space, a minimum of 7.5′ of ceiling clearance and a 6ft long table or 2 short tables for the Photo Booth at the event's venue. Client is responsible for providing 1 standard electrical outlet within 10ft of the booth for the Photo booth (we provide extension cords). We do not setup outdoors if weather conditions are unfavorable for a canopy. We do not provide outdoor shading/canopy. Equipment must be situated away from direct sun, rain, and wind.  Any delay in the performance or damage to the photo booth equipment due to improper power is the responsibility of the client.",
    title9: "DATE CHANGES:",
    paragraph9:"Any request for a date change must be made in writing at least sixty days in advance of the original event date. Change is subject to photo booth availability and receipt of a new Service Agreement. If there is no availability for the alternate date, the deposit shall be forfeited and event cancelled.",
    title10: "EVENT CANCELLATIONS:",
    paragraph10:"Any cancellation requests occurring less than sixty days prior to the event date shall forfeit all payments received. The Provider may substitute another provider to provide photo booth services or issue a full refund in the event of Provider’s illness or of scheduling conflicts. In the event of such substitution, the Provider warrants that the provider taking the photographs shall be a competent professional.",
    title11: "VENUE LOCATION CHANGES:",
    paragraph11:"Any request for a venue location change must be made in writing at least sixty days in advance of the original event date. No travel fees for venues within 35 km of our location. The provider will charge $0.70 per additional kilometer as measured by Google Maps driving directions from business address to event and return. ",
    title12: "EQUIPMENT FAILURES:",
    paragraph12:"Should the Provider fail to provide a fully operational Photo Booth for the Event, the Client's only remedy will be a full refund. In such an event, the Client waives any claim on further consequential damages or liability. If only partial services can be provided due to conditions beyond the reasonable control of the Provider, the payments shall be negotiated at that time on a prorated basis. If physical prints are requested for the event and the printer fails to operate on site the Provider will be allowed to give a web site to the client where their guests can log in and order digital prints free of charge for 15 days after the event.",
    title13: "DAMAGE TO PROVIDER'S EQUIPMENT & REFUSAL:",
    paragraph13:"The Client acknowledges that it shall be responsible for any damage or loss to the Provider's Equipment caused by: a) Any misuse of the Provider's Equipment by the Client or its guests (invited or uninvited) or b) Any theft or disaster (including but not limited to fire, flood or earthquake). The Client acknowledges the Provider shall have the right to decline service to client's guest (invited or uninvited), for misuse, or unruly behavior. For outdoor events, If the Provider judges the weather during the course of the event to be inclement and thus unsafe, they reserve the right to cease operations for the safety of the equipment and of the event's attendees, in which case no refund will be given.",
    title14: "MEDIA USAGE:",
    paragraph14:"The Client agrees to allow Spartacus Entertainment Inc to use any recordings or images captured by the machine for advertising purposes.",
    title15: "LIABILITY AND INDEMNIFICATION:",
    paragraph15:"The Provider will not be liable for direct, indirect, incidental or consequential damages (including, but not limited to, damages for lost profits or increased expenses) with respect to any claim related to this agreement and the services provided. The Client will indemnify and hold harmless the Provider and all technicians working with the Provider at the time against all liability related to the event from its date and into the future. The Client will assume all legal fees claimed by third persons, provided that such loss or damage was not caused by the fault or negligence of the Provider or its employees, agents, or subcontractors.",
    title16: "MISC:",
    paragraph16:"If any provision of these terms shall be unlawful, void, or for any reason unenforceable under Agreement Law, then that provision, or portion thereof, shall be deemed severable from the rest of this agreement and shall not affect the validity and enforceability of any remaining provisions, or portions thereof. This is the entire agreement between the Provider and the Client relating to the subject matter herein and shall not be modified except in writing, signed by both parties. In the event of a conflict between parties, the Client agrees to solve any arguments via arbitration.",
    title17:"APPLICABLE LAW:",
    paragraph17:"This agreement shall be governed by the laws of the Country of Canada in the Province of Ontario and any applicable Federal law.",
    title18:"ENTIRE AGREEMENT:",
    paragraph18:"This Agreement constitutes the entire agreement between the Parties. No modification or amendment of this Agreement shall be effective unless in writing and signed by both Parties.",
    title19:"EXECUTION:",
    paragraph19:"The Provider and the Client each represent and warrant to the other that each person executing this agreement on behalf of each party is duly authorized to execute and deliver this agreement on behalf of that party.",
}
export default strings;