import React from 'react';

interface ValidationMessageProps {
    message: string;
}

const ValidationMessage: React.FC<ValidationMessageProps> = ({ message }) => {
    return (
        <div className="font-poppins text-red-500 text-md lg:text-lg 3xl:text-xl mt-3">
            {message}
        </div>
    );
};

export default ValidationMessage;
