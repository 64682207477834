import React from 'react';
import CardItem, {CardItemData} from '../rows/CardItem';
import { offeringsList } from "../../constants/data";
import strings from '../../constants/strings';

const OfferingsGrid = () => {
    return (
        <div className="bg-secondary-black text-white py-12 px-4">
            <div className="flex-1 flex flex-col justify-center items-center text-center">
                <h1 className="font-bold text-center text-custom-yellow text-2xl md:text-4xl">{strings.offeringsTitle}</h1>
                <div className='flex flex-row justify-center items-center pt-4 pb-10 3xl:pt-6 3xl:pb-12'>
                    <div className="w-32 h-1 rounded-full bg-custom-yellow"></div>
                </div>                
            </div>
            <div className="max-w-screen-2xl md:mx-28">                
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4 md:px-24 xl:px-0">
                    {offeringsList.map((item: CardItemData) => (
                        <CardItem
                            key={item.id}
                            item={item}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OfferingsGrid;
