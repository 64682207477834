import React, { ReactNode } from 'react';

export interface TypeItemData {
    id: number;
    icon?: ReactNode;  
    title?: string;  
    specialText?: string;  
    description?: string;    
}

interface TypeItemProps {
    item: TypeItemData;
}

const TypeItem: React.FC<TypeItemProps> = ({ item }) => {
    return (
        <div className='flex flex-col gap-4 items-center justify-center font-poppins'>
            {item.icon && (
                <div className='text-custom-yellow text-4xl'>
                    {item.icon}
                </div>
            )}
            {item.title && (
                <h4 className='text-white font-bold text-lg lg:text-xl 3xl:text-2xl text-center'>
                    {item.title}
                </h4>
            )}
            {item.specialText && (
                <p className='text-custom-yellow text-2xl lg:text-3xl 3xl:text-4xl text-center font-bold'>
                    {item.specialText}
                </p>
            )}
            <p className='text-white text-xl lg:text-xl 3xl:text-2xl text-center'>
                {item.description}
            </p>            
        </div>
    );
};

export default TypeItem;
