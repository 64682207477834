import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainScreen from './screens/main/MainScreen';
import HomeScreen from './screens/home/HomeScreen';
import ContactUsScreen from './screens/contactUs/ContactUsScreen';
import AboutUsScreen from './screens/aboutUs/AboutUsScreen';
import PackagesScreen from './screens/packages/PackagesScreen';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainScreen />}>
          <Route index element={<HomeScreen />} />
          <Route path="home" element={<HomeScreen />} />
          <Route path="package/:packageId?" element={<PackagesScreen />} />
          <Route path="aboutus" element={<AboutUsScreen />} />
          <Route path="contactus" element={<ContactUsScreen />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
