import React from 'react';
import { frequentlyQuestions } from '../../constants/data';
import QuestionItem, { QuestionItemData } from '../rows/QuestionItem';

interface FrequentlyAskedQuestionsProps {
    title?: string;
    onClick?: () => void;
}

const FrequentlyAskedQuestions: React.FC<FrequentlyAskedQuestionsProps> = ({ title, onClick }) => {

    return (
        <div className='flex flex-col w-full h-full font-poppins items-center my-20'>
            <h3 className='font-bold text-custom-yellow text-center text-2xl md:text-4xl mb-4'>{title}</h3>
            <div className='w-32 max-w-32 h-[6px] rounded bg-custom-yellow' />

            <div className="grid grid-rows-auto grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 py-4 px-8 lg:px-12 mt-12">
                {frequentlyQuestions.map((item: QuestionItemData) => (
                    <QuestionItem
                        key={item.id}
                        item={item} />
                ))}
            </div>
        </div>
    );
};

export default FrequentlyAskedQuestions;