import ContactUsBanner from "../../components/sections/ContactUsBanner";
import MainBanner from "../../components/sections/MainBanner";
import OurPackagesGrid from "../../components/sections/OurPackagesGrid";
import BookingStepsBanner from "../../components/sections/BookingStepsBanner";
import EventTypesGrid from "../../components/sections/EventTypesGrid";
import strings from "../../constants/strings";
import { eventsList } from "../../constants/data";
import FrequentlyAskedQuestions from "../../components/sections/FrequentlyAskedQuestions";

const HomeScreen = () => {
    return (
        <div className="flex flex-col">
            <MainBanner />
            <EventTypesGrid
                title={strings.eventTypesGridTitle}
                content={strings.eventTypesGridContent}
                types={eventsList}
                buttonTitle={strings.contactUs}
                buttonType="primary" />
            <OurPackagesGrid />
            <ContactUsBanner title={strings.contactUsBannerContent} />
            <FrequentlyAskedQuestions title={strings.frequentlyAskedQuestions} />
            <BookingStepsBanner />
        </div>
    );
};


export default HomeScreen;

