import strings from '../../constants/strings';

const AboutUsMainBanner = () => {

    return (

        <div className="w-full h-[590px] flex-col bg-banner-aboutus1 bg-cover bg-center">
            <div className='flex text-white font-poppins w-full h-full bg-black bg-opacity-50'>
                <div className='flex flex-col justify-center w-full xl:w-8/12 px-10 md:px-32 lg:px-32 py-10'>
                    <h2 className='font-bold text-2xl md:text-4xl lg:text-6xl whitespace-pre mb-4'>{strings.aboutUs}</h2>
                    <div className='w-32 h-2 rounded bg-custom-yellow mb-10' />
                    <h4 className='text-md md:text-xl mb-6 mr-0 lg:mr-24'>{strings.aboutBannerText1}</h4>
                    <h4 className='text-md md:text-xl mb-6 mr-0 lg:mr-24'>{strings.aboutBannerText2}</h4>
                    
                </div>
            </div>
        </div>
    );
};

export default AboutUsMainBanner;