import strings from "./strings"
import { BiParty } from "react-icons/bi";
import { FaRegHeart } from "react-icons/fa";
import { RiGraduationCapFill } from "react-icons/ri";
import { MdWorkOutline } from "react-icons/md";

import { TypeItemData } from "../components/rows/TypeItem"
import { package1, package2, package3 } from "../assets/images";
import { BookingStepItemData } from "../components/rows/BookingStepItem";
import { QuestionItemData } from "../components/rows/QuestionItem";
import { CardItemData } from "../components/rows/CardItem";

import { bannerPackage1, bannerPackage2, bannerPackage3 } from '../assets/images';
import { includeImg1, includeImg2, includeImg3 } from '../assets/images';
import { galleryIcon, timeIcon, checkIcon, shapesIcon, handsIcon, } from '../assets/images';

const menuList = [
    { id: 1, name: strings.home, route: "home", isSelected: true },
    { id: 2, name: strings.packages, route: "packages", isSelected: false },
    { id: 3, name: strings.aboutUs, route: "aboutus", isSelected: false },
    { id: 5, name: strings.contactUs, route: "contactus", isSelected: false },
]

const eventsList: TypeItemData[] = [
    { id: 1, icon: <BiParty />, title: "Parties" },
    { id: 2, icon: <FaRegHeart />, title: "Wedding" },
    { id: 3, icon: <RiGraduationCapFill />, title: "Graduations" },
    { id: 4, icon: <MdWorkOutline />, title: "Corporate events" },
]

const packagesList = [
    {
        id: 1,
        title: "Special Events",
        subtitle: "We enjoy making people smile and work hard to make your event special.",
        description: "We enjoy making people smile and work hard to make your event special.",
        image: package1,
        isImageTop: false,
        alt: "Special Events",
        banner: bannerPackage1,
        chooseTitle: "Create memories that last a lifetime. For birthdays, bachelorettes, weddings (and divorces), sweet 16, quinceañeras and holidays, renting a photo booth provides everyone a special keepsake to cherish.",
    },
    {
        id: 2,
        title: "Corporate Events",
        subtitle: "We know corporate events and the time and investment put into them to promote employee engagement or your products and services.",
        description: "We know corporate events and the time and investment put into them to promote employee engagement or your products and services.",
        image: package2,
        isImageTop: false,
        alt: "Corporate Events",
        banner: bannerPackage2,
        chooseTitle: "We will work with you to assure your guests are having an engaging and enjoyable time at your event and will uphold your corporate values."
    },
    {
        id: 3,
        title: "Nonprofit Events",
        subtitle: "Nonprofit organizations rely heavily on client engagement and we understand that every dollar counts.",
        description: "We enjoy working with this sector and offer our services at a reduced cost to aid in your efforts.",
        image: package3, isImageTop: false, alt: "Nonprofit Events",
        banner: bannerPackage3,
        chooseTitle: "We enjoy working with this sector and offer our services at a reduced cost to aid in your efforts. We will work with you to assure your guests are having an engaging and enjoyable time at your event and will uphold your corporate values."
    },
]

const bookingStepsList: BookingStepItemData[] = [
    { id: 1, description: "Determine which package meets your requirements." },
    { id: 2, description: "Determine if you require any customization or have any special requests." },
    { id: 3, description: "Submit your request and await our confirmation." },
    { id: 4, description: "Submit your deposit to secure your booking." },
]

const frequentlyQuestions: QuestionItemData[] = [
    { id: 1, question: "What type of space does the booth require?", answer: "We need a space approximately 8ft x 8ft with access to a power source.  If operating outdoors, a covered location is needed to account for unfavorable weather conditions (rain, snow, windy, etc.).", isExpanded: false },
    { id: 2, question: "What type of booth do you offer?", answer: "We currently offer an open-air mirror photo booth that uses a DSLR camera for high quality images along with a Canon Selphy printer for physical prints if requested.  We will be introducing a 360 booth in the near future.", isExpanded: false },
    { id: 3, question: "Do you offer services outside of the Niagara region?", answer: "We do.  Depending on location we may charge for time and travel to and from the event location.", isExpanded: false },
    { id: 4, question: "Can I provide my own backdrops or props?", answer: "Absolutely!  We just need to know what you are providing so we can accommodate.", isExpanded: false },
    { id: 5, question: "How far ahead do we need to book your services?", answer: "Typically, we request that bookings are made a minimum of 2 weeks before the event.  If you require customization or have special requirements, we would ask that more time is given to assure we are able to accommodate.", isExpanded: false },
    { id: 6, question: "Do you offer photo booth services for longer durations or multiple days?", answer: "We sure do.  Depending on your particular needs we can offer services for more than 8 hours or even over multiple days.  We would need to know the particulars to develop an offering that meets your requirements.", isExpanded: false },
]

const whatsIncludedList: CardItemData[] = [
    { id: 1, imageUrl: includeImg1, size: "square", isImageTop: true, title: "Photo Booth", content: "Open-air Mirror Photo Booth with DSLR camera capable of creating photos, GIFs, boomerangs and videos.  Base package includes 2 hours of run time and digital delivery of the photos via email and SMS (CASL Compliant).  We will also be on site to assure everything runs smoothly.", alt: "photo booth focused on the camara" },
    { id: 2, imageUrl: includeImg2, size: "square", isImageTop: true, title: "Backdrop, lighting, stanchions and prop table", content: "Backdrop, lighting, stanchions and prop table", alt: "studio lights" },
    { id: 3, imageUrl: includeImg3, size: "square", title: "Travel, set-up and teardown", content: "Price includes travel to and from the event within Niagara Region; arriving 1 hour before the event to set up and ½ hour teardown time", alt: "photo booth props like masks and signs" },
]

const howToList: CardItemData[] = [
    { id: 1, imageUrl: galleryIcon, title: "Your event will be memorable", content: "At Spartacus Entertainment our goal is to help you create lasting memories. we’re not just about photo booths; we’re about making memories that last a lifetime!", alt: "galery icon" },
    { id: 2, imageUrl: timeIcon, title: "On-time", content: "We arrive approximately an hour before the event to setup and assure everything is up and running for when your event starts.", alt: "clock icon" },
    { id: 3, imageUrl: checkIcon, title: "Easy to use", content: "Simply walk up to the booth, select the type of image you want to capture and pose.  Guests will have the option to send the images digitally to social media, email address or text.", alt: "check icon" },
    { id: 4, imageUrl: shapesIcon, title: "For all occasions", content: "Will provide props and are able to customize your photo template to add that extra personalized touch.", alt: "shapes icon" },
]

const offeringsList: CardItemData[] = [
    { id: 1, imageUrl: handsIcon, title: "Professional and courteous", content: "We work hard so you and your guests can have a memorable experience.  We also know that we are representing you so we will conduct ourselves with professionalism while maintaining a high level of engagement.", alt: "hands shaking" },
    { id: 2, imageUrl: timeIcon, title: "Always on time", content: "We will arrive early to set up and test the equipment to assure we are ready when the event starts.", alt: "clock icon" },
    { id: 3, imageUrl: checkIcon, title: "High quality equipment", content: "We chose to utilize Canon DSLR cameras and Canon Selphy printers for our booths providing high quality images.", alt: "check icon" },
]

export { menuList, eventsList, packagesList, bookingStepsList, frequentlyQuestions, howToList, whatsIncludedList, offeringsList }