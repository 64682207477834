import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MdOutlineMenu } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";

import { logo2 } from '../../assets/images';
import MenuItem, { MenuItemData } from '../rows/MenuItem';
import { menuList } from '../../constants/data';

const NavMenu = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const [menuData, setMenuData] = useState(menuList);

    const updateMenuSelection = (currentRoute: string) => {
        let updatedMenuData = menuData.map(item => ({
            ...item,
            isSelected: item.route === currentRoute
        }));

        const isAnySelected = updatedMenuData.some(item => item.isSelected);
        if (!isAnySelected) {
            if (currentRoute.includes("package")) {
                updatedMenuData = updatedMenuData.map(item => ({
                    ...item,
                    isSelected: item.id === 2
                }));
            } else {
                updatedMenuData = updatedMenuData.map(item => ({
                    ...item,
                    isSelected: item.route === "home"
                }));
            }
        }

        setMenuData(updatedMenuData);
    };

    useEffect(() => {        
        const currentRoute = location.pathname.substring(1);
        updateMenuSelection(currentRoute);
    }, [location.pathname]);

    const navigateToScreen = (route: string) => {
        setDropdownVisible(false);
        updateMenuSelection(route);
        navigate(route);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (

        <nav>
            <div className="flex flex-row justify-between items-center pr-6 md:pr-8 lg:pr-10 xl:pr-12 font-poppins">
                <img className='w-10/12 md:w-4/12 lg:w-5/12' src={logo2} alt="NF" />
                <div className='hidden md:flex flex-row gap-0 md:gap-5 lg:gap-8 xl:gap-20'>
                    {menuData.map((item: MenuItemData) => (
                        <MenuItem
                            key={item.id}
                            item={item}
                            isDropdownVisible={isDropdownVisible}
                            setDropdownVisible={setDropdownVisible}
                            onClickItem={(route) => navigateToScreen(route)}
                        />
                    ))}
                </div>

                <button className="md:hidden text-2xl text-white" onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? <IoCloseCircleOutline /> : <MdOutlineMenu />}
                </button>

            </div>
            {isMobileMenuOpen && (
                <div className='relative w-full'>
                    <div className='flex flex-col gap-4 p-4 md:hidden bg-custom-black'>
                        {menuData.map((item: MenuItemData) => (
                            <MenuItem
                                key={item.id}
                                item={item}
                                onClickItem={(route) => {
                                    navigateToScreen(route);
                                    toggleMobileMenu();
                                }}
                                isDropdownVisible={isDropdownVisible}
                                setDropdownVisible={setDropdownVisible}
                            />
                        ))}
                    </div>
                </div>
            )}
        </nav>
    );
};

export default NavMenu;